* {
  margin: 0;
  padding: 0;
  font-family: Helvetica;
}

a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
a:active {
  text-decoration: underline;
}

.Welcome {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 0, 0);
}

/*
Background
*/

.App {
  height: 100vh;
  width: 100vw;

  max-height: -moz-available; /* WebKit-based browsers will ignore this. */
  max-height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  row-gap: 20px;
  /*overflow*/
  overflow: auto;
  overflow-x: auto;
  overflow-y: auto;
}

.BackgroundVideo video {
  position: absolute;
  z-index: -10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  z-index: -9;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.349);
}

.spline {
  position: absolute;
  z-index: -8;
  width: 100%;
  height: 100%;
}

/*
ScrollBar 
*/

::-webkit-scrollbar {
  width: 0.5em;
}

::-webkit-scrollbar-track {
  background: rgb(223, 223, 223);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(102, 168, 243);
  border: 0.1em solid rgb(223, 223, 223);
  border-radius: 100vw;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(100, 177, 212);
}

@supports (scrollbar-color: red blue) {
  * {
    scrollbar-color: rgb(102, 168, 243) rgb(236, 236, 236);
    scrollbar-width: thin;
  }
}
