/*
Navigation CSS and page title 
*/

.NavBar {
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.62);
  padding: 20px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
