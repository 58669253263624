.ContentBox {
  /*Size*/
  width: fit-content;
  height: fit-content;
  margin: 0px 0px 100px 0px;

  max-height: 90%;
  max-width: 80%;

  /*flexbox*/
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;

  /*overflow*/
  overflow: auto;
  overflow-x: hidden;

  /*styling*/
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.62);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.ContentListElement {
  /*text*/
  text-align: center;

  /*Size*/
  width: fit-content;
  height: fit-content;
  max-width: 800px;

  /*flexbox*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
  flex-grow: 1;

  /*padding and margins*/
  padding: 5%;
  margin: 5%;

  /*styling*/
  border-radius: 10px;
  background-color: #e6e8e6;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.ContentListElement h1 {
  /*styling*/
  color: #3d5467;
  font-size: clamp(1.125rem, 0.7538rem + 1.5839vw, 3rem);
}

.ContentListElement h2 {
  /*styling*/
  color: #3d5467;
  font-size: clamp(1.125rem, 0.7538rem + 1.5839vw, 3rem);
}

.ContentListElement h3 {
  /*styling*/
  color: #3d5467;
  font-size: clamp(0.875rem, 0.6028rem + 1.1616vw, 2.25rem);
}

.ContentListElement p {
  color: #4b6982;
  font-size: clamp(0.875rem, 0.677rem + 0.8448vw, 1.875rem);
}

.element {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.Profile {
  height: 100%;
  width: 100%;
}

img {
  /*padding and margins*/

  height: auto;
  width: 100%;
}

/*Videos Styling*/

.ReactPlayerWrapper {
  /*Size*/
  aspect-ratio: 16/9;
  height: auto;
  width: 100%;
}

.Details {
  color: #4b6982;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.ListItemContainer {
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px 20px;
}

.ListItemContainer img {
  aspect-ratio: 1/1;
  max-width: 120px;
}
